body {
  margin: 0;
  position: relative;
  padding: 0 !important;
  font-family: "Raleway", "Montserrat", sans-serif;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
body,
html {
  width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 12px;
  background: rgba(173, 216, 230, 1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 51, 102, 1);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 102, 153, 1);
}
