/* HEADER */
.header-view {
  padding: 4% 5%;
  background: #dff0f4;
  border-bottom: 1px solid rgb(204, 204, 204);
  width: 100%;
  position: fixed;
  z-index: 100;
  transition: box-shadow ease 0.7s;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    img {
      width: 112px;
      cursor: pointer;
    }

    .menu-btn {
      cursor: pointer;
    }

    .menu {
      background: rgba(255, 255, 255, 1);
      position: absolute;
      top: 43px;
      right: -10px;
      padding: 7% 4% 7% 15%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border-radius: 2px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

      span {
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: right;
      }
    }
  }
}

.header-fixed {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border: none;
}

/* HOME PAGE */
.home-view {
  width: 100%;
  background: rgba(46, 179, 201, 0.1);
  padding: 20% 5% 8% 5%;

  .overview {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-family: 'Raleway', sans-serif;
      font-size: 32px;
      font-weight: 600;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: left;
    }

    .sub-title {
      font-family: 'Raleway', sans-serif;
      font-size: 30px;
      font-weight: 500;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
    }

    .description {
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
      margin-top: 1rem;
      color: rgba(92, 92, 92, 1);
    }
  }

  .home-banner {
    img {
      max-width: 100%;
      display: block;
      scale: 1;
      transition: all ease 0.3s;

      &:hover {
        scale: 1.04;
      }
    }
  }

  .learn-more-btn {
    width: 100%;
    height: 52px;
    border: none;
    outline: none;
    border-radius: 6px;
    background: rgba(46, 179, 201, 1);
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    color: rgba(255, 255, 255, 1);

    cursor: pointer;

    &:hover {
      background: rgba(46, 179, 201, 0.7);
    }
  }
}

/* WHO WE ARE PAGE */
.info-view {
  padding: 10% 5%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;

    border-bottom: 4px solid rgba(46, 179, 201, 1);
  }

  .sub-title {
    padding: 6% 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;

    color: rgba(92, 92, 92, 1);
  }

  img {
    width: 100%;
    scale: 1;
    transition: all ease 0.3s;

    &:hover {
      scale: 1.02;
    }
  }
}

.user-customer-experience {
  padding: 10% 5%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;

    border-bottom: 4px solid rgba(46, 179, 201, 1);
  }

  .sub-title {
    padding: 6% 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;

    color: rgba(92, 92, 92, 1);
    margin-bottom: 3%;
  }

  .video-container {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 32px;
    position: relative;

    video {
      width: 100%;
      height: inherit;
      border-radius: 32px;
    }

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      z-index: 20;
      display: flex;
      justify-content: center;
      color: #fff;
      height: 70px;
      border-radius: 100%;
      background: #2eb3c9;
      transition: all 0.3s ease-in-out 0s;
      box-shadow: rgba(193, 244, 246, 0.698) 0px 0px 0px 0px;
      animation: 2.5s cubic-bezier(0.8, 0, 0, 1) 0s infinite normal none running
      pulse-2;
      align-items: center;
      border: 0;
      cursor: pointer;
    }

    .play-icon:is(:hover, :focus) {
      background: #fff;
      color: #2eb3c9;
    }

    @keyframes pulse-2 {
      100% {
        box-shadow: 0 0 0 45px rgba(193, 244, 246, 0);
      }
    }
  }
}

.partners-view {
  padding-top: 5%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;

    border-bottom: 4px solid rgba(46, 179, 201, 1);
  }

  .icons-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4rem;
    padding-top: 15%;

    img {
      width: 120px;
      scale: 1;
      transition: all ease 0.3s;

      &:hover {
        scale: 1.03;
      }
    }
  }
}

/* Privacy Policy Page */
.privacy-policy-view {
  padding: 20% 5% 5% 5%;
  line-height: 1.6;

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  ul {
    list-style: disc;
    margin: 10px 0 10px 20px;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  strong {
    font-weight: bold;
  }
}

/* FOOTER */
.footer-view {
  margin-top: 8rem;
  padding: 10% 5%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(13, 52, 73, 1);

  .footer {
    width: 100%;

    .website-info-links {
      width: 100%;
      padding-bottom: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      img {
        width: 220px;
        height: 72px;
        cursor: pointer;
      }

      .quick-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        h6 {
          text-align: center;
          font-family: 'Raleway', sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0px;
          color: rgba(255, 255, 255, 1);
        }

        .links {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          margin-top: 2rem;
          gap: 1.5rem;

          span {
            cursor: pointer;
            font-family: 'Raleway', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0px;

            color: rgba(255, 255, 255, 0.65);
          }
        }
      }
    }

    .product-info-links {
      padding: 4% 0 1% 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .socials {
        display: flex;
        gap: 1rem;
        margin-bottom: 2rem;

        .social-btn {
          display: flex;
          align-items: center;
          padding: 10px;
          border-radius: 50%;
          background: transparent;
          border: 1.5px solid rgba(255, 255, 255, 1);
          cursor: pointer;

          &:hover {
            background: rgba(46, 179, 201, 0.5);
          }
        }
      }

      .rights {
        margin-top: 2rem;
        text-align: right;
        font-family: 'Raleway', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        flex-direction: column;

        a {
          margin-bottom: 0.5rem;
          text-align: center;
          text-decoration: underline;
          cursor: pointer;
          color: rgb(46, 179, 201);
          &:hover {
            color: rgba(46, 179, 201, 0.6);
          }
        }
      }
    }
  }
}

/* Scroll Widget */
.scroll-widget {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  padding: 14px 25px;
  position: fixed;
  bottom: 10%;
  right: 9%;
  background: rgb(46, 179, 201);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 50%;
  z-index: 3000;
  border: none;
  cursor: pointer;
}

/* ============ */
/* DESKTOP VIEW */
/* ============ */
@media screen and (min-width: 768px) {
  /* HEADER */
  .header-view {
    padding: 1% 20%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-links {
        width: 25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          cursor: pointer;
          font-family: 'Raleway', sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 23px;
          letter-spacing: 0em;
          scale: 1;
          transition: all ease 0.3s;

          &:hover {
            scale: 1.04;
          }
        }
      }
    }

    img {
      width: auto !important;
    }
  }

  /* HOME PAGE */
  .home-view {
    padding: 7% 20%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .overview {
      width: 90%;
      z-index: 2;

      .title {
        font-size: 52px;
        font-weight: 600;
        line-height: 79px;
      }

      .sub-title {
        font-size: 52px;
        font-weight: 600;
        line-height: 79px;
      }

      .description {
        padding: 1rem 18rem 2rem 0;
        font-size: 28px;
        line-height: 42px;
        margin: 0;
      }

      .learn-more-btn {
        width: 234px;
        height: 78px;
        border: none;
        outline: none;
        border-radius: 12px;
        padding: 25px 50px 25px 50px;
        background: rgba(46, 179, 201, 1);
        font-family: 'Montserrat', sans-serif;
        font-size: 23px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        color: rgba(255, 255, 255, 1);

        cursor: pointer;

        &:hover {
          background: rgba(46, 179, 201, 0.7);
        }
      }
    }

    .home-banner {
      position: absolute;
      top: 55%;
      right: 25%;
      transform: translate(50%, -50%);
      z-index: 1;
    }
  }

  /* WHO WE ARE PAGE */
  .info-view {
    padding: 8% 18%;

    .title {
      font-size: 52px;
      line-height: 72px;
    }

    .sub-title {
      padding: 2% 6% 0 6%;
      font-size: 28px;
      font-weight: 500;
      line-height: 42px;
    }
  }

  .user-customer-experience {
    padding: 5% 0;

    .title {
      font-size: 52px;
      line-height: 72px;
    }

    .sub-title {
      padding: 2% 6% 0 6%;
      font-size: 28px;
      font-weight: 500;
      line-height: 42px;
    }

    .video-container {
      width: 75%;

      video {
        width: 75%;
      }
    }
  }

  .partners-view {
    .title {
      font-size: 52px;
      line-height: 72px;
    }

    .icons-grid {
      gap: 10rem;
      padding-top: 6%;

      img {
        width: 240px;
      }
    }
  }

  /* Privacy Policy Page */
  .privacy-policy-view {
    padding: 7% 20% 0 20%;
  }

  /* FOOTER */
  .footer-view {
    padding: 0 18%;

    .footer {
      .website-info-links {
        padding: 6rem 0;
        flex-direction: row;
        position: relative;
        justify-content: unset;

        img {
          position: absolute;
        }

        .quick-links {
          width: 100%;
          margin: 0;

          h6 {
            font-size: 20px;
          }

          .links {
            flex-direction: row;
            gap: 4rem;
            margin: 4rem 0 0 3rem;
            span {
              scale: 1;
              transition: all ease 0.3s;

              &:hover {
                scale: 1.04;
              }
            }
          }
        }
      }

      .product-info-links {
        flex-direction: row;
        justify-content: space-between;

        .socials {
          width: 30%;
          margin: 0;
        }

        .solv-logo {
          display: flex;
        }

        .rights {
          width: 30%;
          margin: 0;
          line-height: 19px;
          a {
            margin-bottom: 0.2rem;
            text-align: right;
          }
        }
      }
    }
  }
}
